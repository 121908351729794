import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {useNavigate, useParams, useSearchParams, useLocation} from 'react-router-dom';
import styled  from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../contexts/basket/useBasketContext';
import { TChannel } from '../../../contexts/operator/operator-context';
import useOperatorContext from '../../../contexts/operator/useOperatorContext';
import { Item } from '../../../contexts/site/site-context';
import useSiteContext from '../../../contexts/site/useSiteContext';
import PageSpinnerThree from '../../general/PageSpinnerThree';
import SmallModal from '../../general/SmallModal';
import AppLayout from '../../layouts/app/AppLayout';
import ItemModal from './components/items/modal/ItemModal';
import MenuNav from './components/MenuNav';
import MenuCategory from './components/MenuCategory';
import MenuCategorySelector from './components/MenuCategorySelector';
import OrderModal from './components/order/OrderModal';
import useScrollPosition from "../../../hooks/useScrollPosition";
import MenuFilterRow from './MenuFilterRow';
import PreferencesModal from "./PreferencesModal";
import ChannelClosedWelcomeModal from "./ChannelClosedWelcomeModal";
import CartButton from "./components/order/CartButton";
import {iLog} from "../../../index";
import mixpanel from "mixpanel-browser";
import config from "../../../config/channels";


type MenuPageProps = {
    channel: TChannel;
};

export type Preferences = {
    calories: boolean;
    glutenFree: boolean;
    vegan: boolean;
    vegetarian: boolean;
    celery : boolean;
    'cereals-containing-gluten' : boolean;
    crustaceans : boolean;
    eggs : boolean;
    fish : boolean;
    lupin : boolean;
    milk : boolean;
    molluscs : boolean;
    mustard : boolean;
    peanuts : boolean;
    sesame : boolean;
    soybeans : boolean;
    'sulphur-dioxide-and-sulphites' : boolean;
    'tree-nuts': boolean
};

const MenuPage = ({ channel }: MenuPageProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const scrollPosition = useScrollPosition();

    const { restaurant_id } = useParams();

    // need to get the table number out on dine-in
    const [searchParams] = useSearchParams();

    const [itemShow, setItemShow] = useState(false);
    const [editItem, setEditItem] = useState(false);
    const itemShowRef = React.useRef(itemShow);
    itemShowRef.current = itemShow;

    //const [eatInModalShow, setEatInModalShow] = useState(false);

    const [orderShow, setOrderShow] = useState(false);
    const orderShowRef = React.useRef(orderShow);
    orderShowRef.current = orderShow;

    const [allergensShow, setAllergensShow] = useState(false);
    const [modalItem, setModalItem] = useState<null | Item>(null);

    // controls whether menu is view only or not
    const [viewOnly, setViewOnly] = useState(false);

    const operatorContext = useOperatorContext();
    const siteContext = useSiteContext();
    const basketContext = useBasketContext();


    const [singleSiteAndChannel, setSingleSiteAndChannel] = useState(false);
    const [channelClosedWelcomeShow, setChannelClosedWelcomeShow] = useState(false);

    const [preferencesShow, setPreferencesShow] = useState(false);
    const [preferences, setPreferences] = useState<Preferences>({
        calories: true,
        glutenFree: true,
        vegan: true,
        vegetarian: true,

        celery : false,
        'cereals-containing-gluten' : false,
        crustaceans : false,
        eggs : false,
        fish : false,
        lupin : false,
        milk : false,
        molluscs : false,
        mustard : false,
        peanuts : false,
        sesame : false,
        soybeans : false,
        'sulphur-dioxide-and-sulphites' : false,
        'tree-nuts': false
    });

    useEffect(() => {

        // direct entry from say a QR code..

        if ( searchParams.has('table') ) {
            basketContext.setTableNumber(searchParams.get('table'));
            navigate(`/${config.dineIn.handle}/${restaurant_id}`);
        }

    }, []);



    useEffect(() => {
        window.addEventListener('popstate', handleBrowserBack);
        mixpanel.track('viewed menu page');

        return () => {
            window.removeEventListener('popstate', handleBrowserBack);
        }
    }, []);


    const handleBrowserBack = (event: any) => {
        if (orderShowRef.current || itemShowRef.current) {
            setOrderShow(false);
            setItemShow(false);
            setModalItem(null);
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        siteContext.setChannel(channel);
    }, [channel]);

    useEffect(() => {

        if (
            siteContext &&
            operatorContext &&
            operatorContext.operator &&
            //!siteContext.loading &&
            !operatorContext.loading
        ) {

            let postcode = localStorage.getItem('postcode') ?? '';
            let table = localStorage.getItem('table') ?? '';

            if (table.length > 0) {
                basketContext.setTableNumber(table);
            }

            siteContext.fetchSite(restaurant_id ?? '',
                channel,
                undefined,
                undefined,
                basketContext.tableNumber ?? table ?? undefined,
                postcode
            );

            // TODO: What happens when this fails?
        } else {
            iLog('Site context not fired.', [siteContext, operatorContext, restaurant_id]);
        }
    }, [restaurant_id, operatorContext]);

    useEffect(() => {

        if ( siteContext.site?.channels
            && Object.keys(siteContext.site?.channels).length === 1
            && siteContext.site?.sites_count === 1
        ) {
            setSingleSiteAndChannel(true);
        }

        if ( siteContext.site && channel !== 'catering' ) {

            let channelData = siteContext.site.channels[channel];

            if ( channelData ) {
                iLog('channel data', [channelData, !channelData?.is_open]);
                setViewOnly(!channelData?.is_open);
            }

            if ( searchParams.has('viewOnly') ) {
                setViewOnly(searchParams.get('viewOnly') === '1');
            }

            iLog('viewOnly', viewOnly);
            setChannelClosedWelcomeShow(viewOnly);

        }

    }, [ siteContext.site, viewOnly ]);


    const showItemModal = (item: Item, edit: boolean) => {
        // setViewOnly(searchParams.get('viewOnly') === '1');

        iLog('showItemModal', [edit, item, basketContext.basketItems]);

        setModalItem(item);
        window.history.pushState({ isPopup: true }, 'Item');
        setItemShow(true);
        setEditItem(edit);
    };

    const updatePreferences = (preferences: Preferences) => {
        mixpanel.track('update preferences');
        setPreferences(preferences);
    };

    if (!operatorContext || !operatorContext.operator || !siteContext) {
        return <PageSpinnerThree show={true} />;
    }

    if (operatorContext.loading || siteContext.loading) {
        return <PageSpinnerThree show={true} />;
    }

    const styleVariant = operatorContext.operator?.theme?.v3.ux.menu_layout ?? 'classic';

    return (
        <AppLayout page="menu" banner={siteContext.site?.banner}>

            <Helmet>
                <title>
                    {t`Order`} {operatorContext.operator?.name ?? ''} {t`from`} {siteContext.site?.name ?? ''} -{' '}
                    {t`Powered by orderswift`}
                </title>
            </Helmet>
            {/* <EatInModal show={eatInModalShow} onHide={() => setEatInModalShow(false)} /> */}
            <SmallModal
                showClose
                header={<h5 className="mb-0 mb-md-3 mt-md-2">{t`Allergen information`}</h5>}
                show={allergensShow}
                onHide={() => setAllergensShow(false)}
                html={operatorContext.operator.allergen_info ?? undefined}
            />
            {/*<TimeslotModal show={timeslotShow} onHide={() => setTimeslotShow(false)} />*/}
            <OrderModal
                reset={false}
                items={basketContext.basketItems}
                show={orderShow}
                onHide={() => setOrderShow(false)}
                showItemModal={showItemModal}
                preferences={preferences}
            />
            <ChannelClosedWelcomeModal
                channel={channel}
                show={channelClosedWelcomeShow}
                singleSiteAndChannel={singleSiteAndChannel}
                onHide={() => setChannelClosedWelcomeShow(false)}
            />
            <TopWrapper>
                <div className={'nav-top__logo'}>
                    <MenuNav
                        cartOpen={() => {
                            mixpanel.track('cart button clicked');
                            window.history.pushState({ isPopup: true }, 'Order');
                            setOrderShow(true);
                        }}
                        handleClick={() => setAllergensShow(true)}
                        channel={channel}
                        singleSiteAndChannel={singleSiteAndChannel}
                    />
                    {/* <WhiteBackground></WhiteBackground> */}
                </div>
                <div className={'nav-top__location'}>
                    <MenuFilterRow setPreferencesShow={setPreferencesShow}/>
                </div>
            </TopWrapper>
            <BottomWrapper className={`${scrollPosition > 0 ? 'scrolled-bottom-wrapper' : ''}`}>
                <div>
                    <MenuCategorySelector bannerOffset={siteContext.site?.banner ? true : false} categories={siteContext.menu?.categories} />
                    { operatorContext.operator?.theme.v3.ux.filters && (
                        <div className={'fab__row'}>
                            <MenuFilterButton onClick={() => setPreferencesShow(true)} className={'menu-filter-button'}>
                                <span>
                                    <i className="feather feather-sliders feather-20 brand-color-filter" />
                                    Preferences
                                </span>
                            </MenuFilterButton>
                            <PreferencesModal
                                show={preferencesShow}
                                onHide={() => {
                                    setPreferencesShow(false);
                                }}
                                originalPreferences={preferences}
                                updatePreferences={updatePreferences}
                            />
                        </div>
                    )}
                </div>
                {/*<OrderswiftBanner page={'menu-new'}/>*/}
            </BottomWrapper>
            {/*<Shadow />*/}
            <MenuWrap className="relative menu-wrap">
                {/* <NavShadowCover/> */}
                <MenuContainer
                    variant={styleVariant}
                >
                    {/* <Background className='d-none d-sm-block'></Background> */}
                    <MenuContent>
                        {/* <MenuCategorySelector categories={siteContext.menu?.categories}></MenuCategorySelector> */}
                        <MenuList>
                            {/* <div className="menu-list-shadow" /> */}


                            { /* 20240318: Being hidden while data in HQ is sorted
                               * since with the current data, this UI device would be misused.
                               * Also, make sure it accepts HTML links..

                            siteContext.site?.banner ? (
                                <div className={'promo-card-container'}>
                                    <div className={'promo-card'}>
                                        <div className={'promo-card__icon-wrapper'}>
                                            <i className={'promo-card__icon feather feather-tag feather-20'}></i>
                                        </div>
                                        <div className="promo-card__details">
                                            <p className={'promo-card__title'} dangerouslySetInnerHTML={{ __html: siteContext.site?.banner }}></p>
                                        </div>
                                    </div>
                                </div>
                            ) : null */}
                            <ItemModal
                                show={itemShow}
                                onHide={() => {
                                    setItemShow(false);
                                    setModalItem(null);
                                    setEditItem(false);
                                }}
                                allowItemImages={operatorContext.operator?.allow_menu_item_images ?? false}
                                originalItem={modalItem}
                                viewOnly={viewOnly}
                                edit={editItem}
                                preferences={preferences}
                            />
                            <div className='menuWrapper'>
                                {siteContext.menu?.categories.filter((cat) => cat.name.toLowerCase() !== 'upsell').map((category, index) => (
                                    <MenuCategory
                                        key={category.uid + '-cat'}
                                        category={category}
                                        showItemModal={ showItemModal }
                                        menuItemStyle={operatorContext.operator?.theme?.v3.ux.menu_layout ?? 'classic'}
                                        isFirst={index === 0}
                                        allowItemImages={operatorContext.operator?.allow_menu_item_images ?? false}
                                        preferences={preferences}
                                    />
                                ))}
                            </div>
                        </MenuList>
                    </MenuContent>
                    <FootNote>
                        <div className="fn_calories">
                            <i className="feather feather-info" />
                            <span>Adults need around 2000 kcal a day</span>
                        </div>
                        <div className="fn_dietary_legend">
                            <div>
                                <span className="badge-v">
                                    V
                                </span>
                                <span>Vegetarian</span>
                            </div>
                            <div>
                                <span className="badge-vg">
                                    VG
                                </span>
                                <span>Vegan</span>
                            </div>
                            <div>
                                <span className={'badge-gf'}>
                                    GF
                                </span>
                                <span>Gluten Free</span>
                            </div>
                        </div>
                    </FootNote>

                    {/* <Basket showTimeslotModal={setTimeslotShow} reset={false} site={siteContext.site}></Basket> */}
                </MenuContainer>
            </MenuWrap>
            {/* <StickyDiscountButton>
                {t`Get 50% off your order today! `}
            </StickyDiscountButton> */}

            <OrderButtonContainer>
                <CartButton
                    classes={'mobile-cart-btn'}
                    CTAProps={{ dual: true }}
                    onClick={() => {
                        mixpanel.track('cart button clicked (mobile)');
                        window.history.pushState({ isPopup: true }, 'Order');
                        setOrderShow(true);
                    }}
                />
            </OrderButtonContainer>

        </AppLayout>
    );
};

const FootNote = styled.div`
    .fn_calories {
        font-size: 16px;
        line-height: 24px;
        color: var(--text-grey);
        padding: 0 20px;
        margin-bottom: 12px;
        i { margin-right: 8px; }
        @media screen and (min-width: 768px){
            padding: 0 48px;
        }
    }
    .fn_dietary_legend {
        .badge-vg { color: #449539; }
        .badge-gf { color: #EC750A;  }
        .badge-h { color: #EB4B7B;  }
        > div { 
            display: inline-block; margin-right: 8px;
            font-size: 16px;
            line-height: 24px;
            color: var(--text-grey);
            span:first-child { margin-right: 8px; display: inline-block; }
        }
        padding: 0 20px;
        @media screen and (min-width: 768px){
            padding: 0 48px;
        }
        
    }
`;

const MenuFilterButton = styled.button`
    box-shadow: 0 2px 2px 1px rgba(0,0,0,0.05);
    pointer-events: auto;
    padding: 8px 16px;
    position: relative;
    color: var(--ui_buttons_change_location_color);
    font-family: var(--text_ui_font);
    text-transform: var(--text_ui_case);
    font-weight: 500;
    font-size: var(--ui_buttons_change_location_size);
    line-height: 20px;
    color: var(--brand);
    border-radius: 6px;
    border: 1px solid var(--border-grey);
    background-color: #FFFFFF;
    transition: background-color linear 0.15s, transform linear 0.15s;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    .icon {
        vertical-align: middle;
        font-weight: 700;
    }
    .icon--rotate {
        transform: rotate(90deg);
    }
    span {
        position: relative;
        display: inline-flex;
        align-items: center;
        gap: 8px;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom:0;
        border-radius: 6px;
        z-index: 0;
        background-color: white;
    }
    &:hover {
        box-shadow: 0 3px 4px 1px rgba(0,0,0,0.1);
        transform: translateY(-2px);
        &:before {
            background-color: var(--bg-grey);
        }
    }
`;

const TopWrapper = styled.div`
    position: relative;
    background-color: var(--layouts_headers_primary_bg);
    z-index: 11;
    padding: 0;
    border-bottom: var(--layouts_headers_borders_menu);
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    .nav-top__logo {
        max-width: 1920px;
        padding: 0 48px;
        margin: 0 auto;
        width: 100%;
        @media screen and (max-width: 767px) { padding: 0 20px; }
    }
    
    .nav-top__location {
        max-width: 1920px;
        margin: 0 auto;
        width: 100%;
        @media screen and (min-width: 768px) { display: none }
    }
    @media screen and (min-width: 768px) {
        height: 65px;
        align-items: center;
        flex-direction: row;
        position: sticky;
        top:0;
        z-index: 11;
    }
`;

const MiddleWrapper = styled.div`
    @media screen and (min-width: 768px) {
        display: none;
    }
`;

const BottomWrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--ui_nav_menu_bg);
    border-bottom: var(--layouts_headers_borders_menu);
    max-width: 100%;
    > div {
        max-width: 1920px;
        margin: 0 auto;
        display: flex;
        gap: 16px;
        align-items: center;
        flex-wrap: nowrap;
    }
    @media screen and (min-width: 768px) {
        top: 65px;
    }

    &.scrolled-bottom-wrapper {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    }

    
`;

const MenuList = styled.div`
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    h5 {
        font-size: 32px;
    }
    .menu-list-shadow {
        position: absolute;
        top: 50px;
        height: 16px;
        background-color: #FFFFFF;
        left: 0;
        right: 0;
        z-index: 20;
        @media screen and (min-width: 768px) {
            display: none;
        }
    }
    .menu-list-alert {
        padding: 0 1rem;
        margin-bottom: 1.5rem;
        margin-top: 1rem;
        display: none;
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    @media screen and (min-width: 768px) {
        position: static;
    }

    @media screen and (min-width: 1140px) {
        padding-top: 0;
        margin-top: 0;
    }

    .menuWrapper {
        padding-top: 32px;

        .menu-category:first-child h5 {
            position: relative;
            /* top: 3px; */
        }
    }
    
    .promo-card-container {
        padding: 0 48px;
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    .promo-card {
        border-radius: 8px;
        border: 1px solid var(--border-grey);
        padding: 16px 24px;
        gap: 16px;
        display: flex;
        align-items: center;
        background-color: var(--white);
        @media screen and (max-width: 767px) {
            padding: 16px;
            gap: 12px;
        }
    }
    
    .promo-card__icon-wrapper {
        flex-shrink: 0;
        color: var(--brand);
    }
    
    .promo-card__details {
        flex-grow: 1;
    }
    
    .promo-card__title {
        font-size: var(--core_text_ui_lg);
        line-height: 20px;
        margin-bottom: 0;
        color: var(--text-black);
        font-weight: 400;
        @media screen and (min-width: 576px) {
            font-size: var(--core_text_ui_lg_desktop);
        }
    }
    
    /* Mobile styles */
    @media (max-width: 767px) {
        .promo-card-container {
            padding: 0 20px; 
            margin-top: 20px;
            flex-direction: column;
        }
        .promo-card {
            width: 100%; 
        }
    }
    
    /* Tablet and desktop styles */
    @media (min-width: 768px) {
        .promo-card {
            width: calc(50% - 16px);
        }
    }
    @media (min-width: 1020px) {
        .promo-card {
            width: calc(33.333% - 32px);
        }
    }
`;

const OrderButtonContainer = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: flex;
        position: fixed;
        z-index: 999;
        padding: 16px 20px;
        background: white;
        margin: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-top: 1px solid var(--border-grey);
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        justify-content: center;

        button {
            height: 48px;
            padding: 0 20px;
        }
    }
`;
const MenuContent = styled.div`
    min-width: 0;
    flex: 1;
    @media screen and (min-width: 1140px) {
        display: flex;
    }
`;

const MenuWrap = styled.div`
    background: var(--layouts_body_menu_bg);
`;

const MenuContainer = styled.div<{ variant?: string }> `
    //    background: var(--layouts_body_menu_bg);
    background: #FFFFFF;
    max-width: 1440px;
    margin: 0 auto;
    display: block;
    flex: 1;
    padding-bottom: 150px;
    position: relative;
    @media screen and (min-width: 768px) {
        background: var(--layouts_body_menu_bg);
        display: flex;
        flex-direction: column;
    }

    ${(props) => props.variant === 'portrait' ? "background: var(--layouts_body_menu_bg);" : ""}

    .locationClosed__alert {
        position: fixed;
        left: 0;
        bottom: 0;
        background: #2E3333;
        max-width: 100vw;
        width: 100vw;
        color: white;
        padding: 18px 48px;
        display: flex;
        align-items: center;

        img { margin-right: 8px; }

        a {
            margin-left: 4px;
            color: white;
            text-decoration: underline;
        }
    }
`;


export default MenuPage;