import React from 'react';
import { Container, Navbar as Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TPage } from '../AppLayout';
import useScrollPosition from "../../../../hooks/useScrollPosition";
import mixpanel from "mixpanel-browser";

interface NavbarProps {
    page: TPage;
}

const Navbar = ({page }: NavbarProps) => {
    const scrollPosition = useScrollPosition();

    const navigate = useNavigate();
    //if (page === 'sites' || page === 'menu') return null;
    if (page === 'menu') return null;


    return (
        <StyledNav className={`${scrollPosition > 64 ? 'scrolled' : ''} nav-${page}`}>
            <StyledContainer>
                {page !== 'checkout' && page !== 'confirmation' && <StyledNavBrand onClick={() => {
                    mixpanel.track('Brand logo clicked');
                    navigate('/');
                }} className="logoClass" />}

                {page === 'confirmation' && (
                    <StyledNavBrand center onClick={() => {
                        mixpanel.track('Brand logo clicked');
                        navigate('/');
                    }} className="logoClass" />
                )}
                {page === 'checkout' && (
                    <StyledCheckoutNav>
                        <div className='nav-checkout__btn'>
                            <button onClick={() => {
                                mixpanel.track('Back chevron clicked (checkout)');
                                navigate(-1);
                            }} className="mr-3">
                                <i className="feather feather-chevron-left feather-24 brand-color-filter"/>
                            </button>
                        </div>
                        <h2 className="nav-checkout__title nav-checkout-title">Checkout</h2>
                    </StyledCheckoutNav>
                )}

                {/* {(page === 'confirmation') && (
                    <a role="button" href="/" className="d-sm-none">
                        <img src={close} alt="" />
                    </a>
                )} */}
            </StyledContainer>
        </StyledNav>
    );
};

const StyledCheckoutNav = styled.div`
    width: 100%;
    position: relative;
    .nav-checkout {
        &__btn {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            @media screen and (min-width: 1176px) {
                left: 0px;
            }
           button {
                border: none;
                background-color: #FFFFFF;
                
                padding: 12px;
                width: 40px;
                height: 40px;
                margin-left: -8px;
                position: relative;
                z-index: 2;
                color: var(--brand);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                &:hover { text-decoration: none; }
                &:hover span { text-decoration: underline }
                
                transition: background-color 0.15s ease-in-out;
                :hover {
                    background-color: var(--brand-10);
                }
                span {
                  font-size: 14px; line-height: 20px;
                
                    @media screen and (max-width: 767px) {
                        display:none;
                    }
                }
            }
        }
        &__title {
            padding: 0 48px;
            text-align: center;
            margin: 0;
        }
    }
`;

const StyledContainer = styled(Container)`
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
`;

const StyledNavBrand = styled.div<{center?: boolean}>`
    background-image: var(--logo);
    background-size: contain;
    background-repeat: no-repeat;
    height: var(--logo_height);
    ${props => props.center ? `
        margin: 0 auto; 
        background-position: center center;
    ` : `
        margin: 0; 
        background-position: center left;
    `};
    width: 290px;
    max-width: 85vw;
    display: block;
    cursor: pointer;
    /* @media screen and (min-width: 576px) {
        height: 33px;
        width: 214px;
    } */
`;
const StyledNav = styled(Nav)`
    height: 64px;
    position: relative;
    z-index: 999;
    background-color: var(--layouts_headers_primary_bg);
    display: flex;
    align-items: center;
    margin: 0;
    padding: 12px 20px;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;


    &.scrolled {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    }

    h5 {
        margin: 0;
    }

    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    @media screen and (min-width: 993px) {
        padding: 12px 48px;
    }

    &.nav-checkout,
    &.nav-confirmation
    {
        height: 64px;
        border-bottom: 1px solid var(--border-grey);
        background-color: white;

        .container {
            max-width: 1092px;
            margin: 0 auto;
        }

    }
    &.nav-confirmation {
        background-color: var(--layouts_headers_primary_bg);
        @media screen and (min-width: 993px) {
            .container {
                padding: 0 32px;
            }
        }
    }
    &.nav-channels {
        border-bottom: var(--layouts_headers_borders_channels);
        @media screen and (min-width: 768px) {
            padding: 12px 48px;
        }
    }
    &.nav-order-at-table {
        border-bottom: var(--layouts_headers_borders_channels);
    }
    
    &.nav-checkout {
        @media screen and (min-width: 768px) {
            padding: 12px 20px;
        }
        @media screen and (min-width: 993px) {
            padding: 12px 48px;
        }
    }

    &.nav-sites {
        position: relative;
        border-bottom: var(--layouts_headers_borders_sites);
        &.scrolled {
            box-shadow: none;
            border-bottom: 0;
        }
    }

`;

export default Navbar;
