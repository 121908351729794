import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../contexts/basket/useBasketContext';
import { money } from '../../../../../services/format';
import {CTA} from '../../../../general/Button';
import PageSpinnerThree from "../../../../general/PageSpinnerThree";
import {iLog} from "../../../../../index";
import useSiteContext from "../../../../../contexts/site/useSiteContext";


interface CheckoutButtonProps {
    showTimeslotHelper: (show: boolean) => void;
    setTimeslotError: (message: string|null) => void;
}

const CheckoutButton = ({ showTimeslotHelper, setTimeslotError }: CheckoutButtonProps) => {
    const basketContext = useBasketContext();
    const siteContext = useSiteContext();

    const renderButtonContent = () => {
        if (basketContext.loading) {
            return <StyledSpinnerOverlay show={true} className={'spinner-overlay'}>
                <PageSpinnerThree show={true} color={'#FFFFFF'}/>
            </StyledSpinnerOverlay>
        }

        return (
            <>
                {/*<span className="count_wrap">*/}
                {/*    <span className="count">*/}
                {/*        <i className="feather feather-shopping-cart feather-20" />*/}
                {/*        <span className={` ${basketContext.loading || !basketContext.basketItems.length ? '' : 'basket-btn-quantity--show'}`}>*/}
                {/*            {basketContext.basketItems.length}*/}
                {/*        </span>*/}
                {/*    </span>*/}
                {/*</span>*/}
                <span>{t`Go to checkout`}</span>
                {/*<span className="basket-btn-money">{money(basketContext.price.total)}</span>*/}
            </>
        );
    };

    const checkout = async () => {
        showTimeslotHelper(false);
        setTimeslotError(null);

        iLog('timeslot?', basketContext.timeslot);

        if ( !basketContext.timeslot ) {
            showTimeslotHelper(true);
            return;
        }

        try {
            await basketContext.validateBasket(false); // .then(() => navigate('/checkout'));// .then( redirect) .catch (error with basket())
        } catch (error: any) {
            if (error.code && [847, 848, 849, 862].includes(error.code)) {
                setTimeslotError(error.message);
                if ( error.extra_data.length > 0 ) {
                    siteContext.setTimeslots(error.extra_data);
                }
            }
            basketContext.setLoading(false);
        }
    };

    if (!basketContext.basketItems.length) {
        return null;
    }

    return (
        <StyledButton
            onClick={checkout}
            disabled={basketContext.loading}
            type="button"
            jumbo
            block
            dual
            cart
        >
            {renderButtonContent()}
        </StyledButton>
    );
};

const StyledSpinnerOverlay = styled.div<{show: boolean}>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #EBEBEB;
    transition: opacity 0.1s ease-in-out;
    opacity: ${(props) => props.show ? '1' : '0'};
    border-radius: 6px;
`;


const StyledButton = styled(CTA)`
    background-color: var(--ui_buttons_cta_basket_bg);
    color: var(--ui_buttons_cta_basket_color);
    justify-content: center;
    .count_wrap {
        width: 90px;
        display: flex;
    }
    .count {
        position: relative;
        width: auto;
        display: flex;
        align-items: center;
        gap: 10px;
        &__items {
            display: none;
            @media screen and (min-width: 768px) {
                display: inline-block;
                margin-left: 8px;
            }
        }
        i {
            margin-right: 10px;
        }
        span {
          line-height: 20px;
        }
    }
    
    &.disabled .count span, 
    &[disabled] .count span, 
    &.disabled:hover .count span, 
    &[disabled]:hover .count span {
        color: var(--ui_buttons_cta_disabled_bg) !important;
    }


    .basket-btn-money {
        width: 90px;
        text-align: right;
    }
    
    .spinner-grow {
        height: 24px;
        width: 24px;
        position: relative;
        left: 50%;
        margin-left: -12px;
    }

`;

export default CheckoutButton;
