import React, {useContext, useEffect, useRef, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { t } from 'ttag';
import useOrderContext from '../../../contexts/order/useOrderContext';
import PageSpinnerThree from '../../general/PageSpinnerThree';
import AppLayout from '../../layouts/app/AppLayout';
import ScrollToTop from '../../../hooks/scrollToTop';
import {useParams} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import powered_by from "../../../assets/images/OrderswiftLogo2025.png";
import {getOrder} from "../../../services/api/basket";
import { TOrder } from '../../../contexts/order/order-context';
import OrderSummary from "./components/OrderSummary";
import Actions from "./components/Actions";
import DefaultChannel from "./components/DefaultChannel";
import Delivery from "./components/Delivery";
import ErrorPage from "../Error/ErrorPage";

const ConfirmationPage = () => {

    const {ref} = useParams();

    const orderContext = useOrderContext();

    const [contextError, setContextError] = useState<string|null>(null);


    const POLLING_INTERVAL = 30 * 1000; // in ms

    useEffect(() => {
        mixpanel.track('viewed confirmation page');

        getOrder(ref)
            .then((data: TOrder | null) => {

                orderContext.setOrder(data);
                orderContext.setStoredOrder(data);

            })
            .catch((reason) => {
                setContextError(reason.message);
            });

    }, []);

    useEffect(() => {

        const intervalId = setInterval(() => {

            // only poll when the order is already set and the channel is delivery..
            
            if ( ref && orderContext && ['delivery', 'catering'].includes(orderContext.storedOrder?.channel ?? '') ) {
                getOrder(ref)
                    .then((data: TOrder | null) => orderContext.setOrder(data))
                    .catch((reason) => {
                        setContextError(reason.message);
                    });
            }


        }, POLLING_INTERVAL);


        return () => clearInterval(intervalId); // Cleanup on component unmount

    }, [ref]);



    if ( contextError ) {
        return <ErrorPage message={contextError} title={'Oops!'} />;
    }

    if (!orderContext || !orderContext.order) {
        return <PageSpinnerThree show={true} />;
    }

    if (orderContext.loading) {
        return <PageSpinnerThree show={true} />;
    }




   /* const AsapLabel = (input: moment.MomentInput) => {
        const inputDateTime = moment(input, "YYYY-MM-DD HH:mm");
        const currentDate = moment();

        // Check if the input date is today
        if (inputDateTime.isSame(currentDate, 'day')) {
            // If it's today, format the time as "Today HH:mm"
            return "Today " + inputDateTime.format("HH:mm");
        } else {
            return inputDateTime.format("DD MM HH:mm");
        }
    }*/





    return (
        <AppLayout page="confirmation">

            <ScrollToTop />
            <Helmet>
                <title>{t`Confirming your order - Powered by orderswift`}</title>
            </Helmet>

            <StyledContainer>
                <StyledRow>
                    <div className={'conf__pane'}>
                        { orderContext.order.channel !== 'delivery' || (['delivery', 'catering'].includes(orderContext.order.channel) && orderContext.order.delivery.provider === 'self') ? ( <DefaultChannel /> ) : null }
                        { ['delivery', 'catering'].includes(orderContext.order.channel) && orderContext.order.delivery.provider !== 'self' ? ( <Delivery /> ) : null }
                    </div>


                    <div className={'conf__pane conf__pane--end'}>
                        <StyledCard>
                            <Actions />
                        </StyledCard>

                        <StyledCard border mobileBorderTop>
                            <OrderSummary />
                        </StyledCard>
                    </div>
                </StyledRow>

                <div className='orderswift-banner-confirmation'>
                    <img width={169} height={15} src={powered_by} alt={'Powered by Orderswift'} />
                </div>
            </StyledContainer>
        </AppLayout>
    );
};



const StyledContainer = styled.div`
    max-width: 1092px;
    padding: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    
    .orderswift-banner-confirmation {
        z-index: 999;
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        border-top: 1px solid #000;
        padding: 10px 0;
    }
    
    .conf {
        &__thanks {
            padding: 32px 20px 12px 20px;
            margin-bottom: 0;
            @media screen and (min-width: 993px) {
                padding: 0;
                margin-bottom: 32px;
            }
        }
        &__note {
            color: var(--brand);
            font-size: var(--core_text_ui_lg);
            @media screen and (min-width: 576px) {
                font-size: var(--core_text_ui_lg_desktop);
            }
            line-height: 24px;
            @media screen and (max-width: 992px) {
                border-bottom: 1px solid var(--border-grey);
            }
            &__header {
                display: flex;
                gap: 8px;
                margin-bottom: 12px;
                i {line-height: 24px;}
            }
        }
        &__heading {
            margin-bottom: 16px;
            &--order {
                padding-top: 0;
                @media screen and (max-width: 992px) {
                    border-bottom: 1px solid var(--border-grey);
                    padding: 20px;
                    margin-bottom: 0;
                }
            }
        }
        &__map {
            @media screen and (min-width: 993px){
                margin-left: -1px;
                margin-top: -1px;
                margin-right: -1px;
                height: 150px;
                &-content {
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-width: 0 1px 1px 1px;
                    border-style: solid;
                    border-color: var(--border-grey);
                }
            }
        }
        &__info {
            display: flex;
            flex-direction: column;
            gap: 8px;
            
            &__channel, &__site_name, &__timeslot {
                font-size: var(--core_text_ui_lg);
            @media screen and (min-width: 576px) {
                font-size: var(--core_text_ui_lg_desktop);
            }
                line-height: 24px;
            }
            &__channel {
                font-weight: 500;
            }   
        }
        &__pane {
            width: 100%;
            &--end {
                padding-bottom: 32px;
            }
            @media screen and (min-width: 993px) {
                padding: 48px 32px;
                max-width: 546px;
                border-color: var(--border-grey);               
            }
        }
        &__receipt-items {
            padding: 12px 0;
            @media screen and (min-width: 993px){
                border-top: 1px solid var(--border-grey);
            }
        }
        &__receipt-item {
            display: flex;
            gap: 12px;
            padding: 4px 20px;
            font-size: var(--core_text_ui_lg);
            @media screen and (min-width: 576px) {
                font-size: var(--core_text_ui_lg_desktop);
            }
            line-height: 24px;        
            &__name {
                flex-grow: 1;
            }
            @media screen and (min-width: 993px){
                padding: 4px 0;
            }
            &--bold {
                font-weight: 600;
            }
        }
        &__site-detail {
            display: flex;
            gap: 12px;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            &__icon {
                height: 20px;
                width: 20px;
                flex-shrink: 0;
                color: var(--text-grey);
            }
            &__text {
                flex-grow: 1;
                span {
                    display: block;
                    margin-bottom: 8px;
                }
                a {
                    color: var(--brand);
                }
            }
        }
    }
`;

const StyledRow = styled.div`
    @media screen and (min-width: 993px) {
        display: flex;
    }


    .basket__order-info {
        .privacy-notice {
            font-size: var(--core_text_ui_sm);
            font-weight: 400;
            color: var(--text-grey);

            padding-bottom: 0 !important;

            a {
                color: var(--text-grey);
                text-decoration: underline;
                font-weight: 500;
            }
            @media screen and (min-width: 576px) {
                font-size: var(--core_text_ui_sm_desktop);
            }
        }
        

        &--desktop {
            display: none;
            @media screen and (min-width: 993px) {
                display: block;
            }
        }
        &--mobile {
            @media screen and (min-width: 993px) {
                display: none;
            }
        }
       
    }
    .only-show-mobile {
        @media screen and (min-width: 993px) {
            display: none;
        }
    }
    .only-show-desktop {
        display: none;
        @media screen and (min-width: 993px) {
            display: block;
        }
    }s

`;
const StyledOrderDetails = styled.div`
    align-self: flex-start;
    width: 100%;
    border-top: 1px solid var(--border-grey);
    @media screen and (min-width: 993px) {
        max-width: 432px;
        border: 1px solid var(--border-grey);
        border-radius: 8px;
        padding: 20px 0;
        position: sticky;
        top: 128px;
    }
`;


// CONFIRMATION
const StyledBlock = styled.div<{bg_var?: string, borderTop?: boolean, noMobilePadding?: boolean}>`
    width: 100%;
    ${(props) => props.noMobilePadding ? null : "padding: 20px;"}
    background-color: var(--${(props) => props.bg_var ?? 'white'});
    ${(props) => props.borderTop ? 'border-top: 1px solid var(--border-grey);' : null}
    @media screen and (min-width: 993px) {
        padding: 20px;
    }
`;


const Instructions = styled.div`
    padding: 18px;
    margin-bottom: 24px;
    border-radius: 4px;
    
    background-color: var(--bg-grey);
    color: var(--text-black);
    i {
        margin-right: 12px;
    }
`;


const StyledCard = styled.div<{mobileBorderTop?: boolean, border?: boolean}>`  
    ${(props) => props.mobileBorderTop ? 'border-top: 1px solid var(--border-grey);' : ''}
    @media screen and (min-width: 993px){
        ${(props) => props.border ? 'border: 1px solid var(--border-grey);' : ''}
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 32px;
    }
`;


export default ConfirmationPage;
